/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint complexity:0 */

import React, { useState, useEffect, useCallback } from 'react'
import { navigate, Link } from 'gatsby'
import { get, some } from 'lodash'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'

import SEO from '../../components/SEO'
import SignInWithFacebook from '../../components/SignInWithFacebook'
import SignInWithApple from '../../components/SignInWithApple'
import { Checkbox, Form, Loader } from '../../_shared/components'
import { client } from '../../apollo/client'
import {
  DOWNLOAD_FROM_APPLE,
  DOWNLOAD_FROM_GOOGLE,
} from '../../data/download-links'
import ME_QUERY from '../../queries/me'
import VERIFY_VOUCHER_QUERY from '../../queries/verify-voucher'
import REDEEM_VOUCHER_MUTATION from '../../queries/redeem-voucher'
import GIVE_CONSENT_MUTATION from '../../queries/give-consent'
import REVOKE_CONSENT_MUTATION from '../../queries/revoke-consent'
import useLogin from '../../hooks/login'
import useSignup from '../../hooks/signup'
import useSocialSignup from '../../hooks/sogicalSignup'
import { trackStepForGTM } from '../../lib/ga'

export default function Page() {
  const [step, setStep] = useState(
    (typeof window !== 'undefined'
      ? window.location.hash.match(/^#?(.*)/)[1]
      : null) || 'login-or-register',
  )

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')

  const [authType, setAuthType] = useState(null)
  const errorState = useState(null)
  const [error, setError] = errorState
  const { loading, data: { me } = {}, refetch } = useQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
  })

  const [
    verifyVoucher,
    {
      called: verifyCalled,
      loading: loadingVerification,
      data: {
        canRedeemVoucher: { eligible, issues = [] } = {},
        verifyVoucher: { valid, voucher } = {},
      } = {},
    },
  ] = useLazyQuery(VERIFY_VOUCHER_QUERY, { fetchPolicy: 'network-only' })

  const [giveConsent] = useMutation(GIVE_CONSENT_MUTATION)
  const [revokeConsent] = useMutation(REVOKE_CONSENT_MUTATION)

  const [redeemVoucher] = useMutation(REDEEM_VOUCHER_MUTATION)

  const { token: loginToken, login } = useLogin(
    errorState,
    setAuthType,
    email,
    password,
  )

  const { token: signupToken, signup } = useSignup(
    errorState,
    setAuthType,
    email,
    password,
  )

  const { token: socialSignupToken, signup: socialSignup } = useSocialSignup(
    errorState,
    setAuthType,
  )

  const moveToStep = useCallback(step => {
    window.location.hash = step
    trackStepForGTM(step)
    setStep(step)
  }, [])

  // Persist token to session storage
  useEffect(() => {
    const token = loginToken || signupToken || socialSignupToken

    async function setToken() {
      if (!window.sessionStorage.getItem('token') && token) {
        window.sessionStorage.setItem('token', token)
        await client.resetStore()

        // refetch user data after setting token
        await refetch()
      }

      // go to next step
      if (token) {
        moveToStep('redeem')
      }
    }

    setToken()
  }, [loginToken, moveToStep, refetch, signupToken, socialSignupToken])

  // Direct users to correct steps to prevent mid-wizard starts
  useEffect(() => {
    // Redirect to login/register selection if not on one of those steps and no signed in user
    if (
      !me &&
      ['login-or-register', 'login', 'register'].indexOf(step) === -1
    ) {
      return moveToStep('login-or-register')
    }

    // Redirect to redeem step if user is signed in, and is on the login/signup steps
    if (me && ['login-or-register', 'login', 'register'].indexOf(step) > -1) {
      return moveToStep('redeem')
    }

    if (me && step === 'confirm' && (!verifyCalled || !code)) {
      return moveToStep('redeem')
    }
  }, [code, loadingVerification, me, moveToStep, step, verifyCalled])

  // Handle verification response
  useEffect(() => {
    setError(null)

    if (!verifyCalled || loadingVerification) {
      return
    }

    if (!valid) {
      return setError('Gavekortet er brukt eller ugyldig')
    }

    moveToStep('confirm')
  }, [loadingVerification, moveToStep, setError, valid, verifyCalled])

  const handleAppleIDLogin = data => {
    setError(null)

    socialSignup(get(data, 'detail.authorization.id_token'), 'apple')
  }

  const handleFacebookLogin = response => {
    setError(null)

    if (response.status === 'unknown') {
      setError('Innloggingen ble avbrutt')
      return
    }

    socialSignup(get(response, 'authResponse.accessToken'), 'facebook')
  }

  const getConsentHandler = type => ({ target: { checked } }) => {
    if (checked) {
      giveConsent({ variables: { type } })
    } else {
      revokeConsent({ variables: { type } })
    }
  }

  function handleAppleIDError(error) {
    if (error.detail.error === 'popup_closed_by_user') {
    }
    setError('Innloggingen med AppleID feilet')
  }

  async function handleRedeemVoucher() {
    setError(null)

    const { data } = await redeemVoucher({
      variables: {
        code: `${code}`.toLocaleUpperCase(),
      },
    })

    if (!get(data, 'redeemVoucher') || !get(data, 'redeemVoucher.success')) {
      return setError('Noe gikk galt ved innløsing av gavekortet')
    }

    moveToStep('success')
  }

  const REDEEM_STEP = {
    title: 'Løs inn gavekort',
    ...(!me ? { backTo: 'login-or-register' } : {}),
    items: [
      {
        item: 'input',
        type: 'text',
        key: 'code',
        label: 'Din kode',
        name: 'code',
        value: code,
        onChange: setCode,
      },
      {
        id: 'verify-error',
        item: 'warning',
        value: (verifyCalled && !loadingVerification && error) || null,
      },
    ],
    onSubmit: () =>
      verifyVoucher({
        variables: {
          code: `${code}`.toLocaleUpperCase(),
        },
      }),
    submit: 'Gå videre',
    submitProps: { disabled: !code.length },
  }

  const LOGIN_OR_REGISTER_STEP = {
    title: 'Har du en bruker?',
    items: [
      {
        item: 'text',
        value:
          'For å løse inn et gavekort må du logge inn eller registrere deg.',
      },
      {
        item: 'button',
        label: 'Logg inn',
        id: 'loggin',
        primary: true,
        spaceless: true,
        full: true,
        onClick: () => moveToStep('login'),
      },
      {
        item: 'text',
        value: 'eller',
        id: 'or',
        tight: true,
      },
      {
        item: 'button',
        label: 'Registrer deg',
        id: 'register',
        primary: true,
        full: true,
        onClick: () => moveToStep('register'),
      },
    ],
  }

  const LOGIN = {
    title: 'Logg inn',
    backTo: 'login-or-register',
    items: [
      {
        id: 'social-login',
        item: 'custom',
        children: (
          <>
            <div
              style={{
                // Hack.... Make a button group component?
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <SignInWithApple
                onSuccess={handleAppleIDLogin}
                onError={handleAppleIDError}
                onClick={() => setError(null)}
              />

              <SignInWithFacebook
                onClick={() => {
                  setError(null)
                  setAuthType('social')
                  FB.login(handleFacebookLogin)
                }}
              />

              {authType === 'social' && error ? <p>{error}</p> : null}
            </div>
          </>
        ),
      },
      {
        item: 'input',
        type: 'email',
        key: 'email',
        label: 'E-post',
        name: 'email',
        value: email,
        onChange: setEmail,
      },
      {
        item: 'input',
        type: 'password',
        key: 'password',
        label: 'Passord',
        name: 'password',
        value: password,
        onChange: setPassword,
      },
      {
        id: 'login-error',
        item: 'warning',
        value: (authType === 'credentials' && error) || null,
      },
    ],
    onSubmit: login,
    submit: 'Logg inn',
    submitProps: { disabled: !email.length || !password.length },
  }

  const REGISTER_STEP = {
    title: 'Registrer deg',
    backTo: 'login-or-register',
    disclaimer: (
      <>
        Ved å fortsette godkjenner du <Link to="/vilkar">vilkårene</Link> for
        kjøp og bruk av Foodsteps og bekrefter at du har lest{' '}
        <Link to="/personvern">personvernerklæringen</Link>.
      </>
    ),
    items: [
      {
        id: 'social-login',
        item: 'custom',
        children: (
          <>
            <div
              style={{
                // Hack.... Make a button group component?
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <SignInWithApple
                onSuccess={handleAppleIDLogin}
                onError={handleAppleIDError}
                onClick={() => setError(null)}
              />

              <SignInWithFacebook
                onClick={() => {
                  setError(null)
                  setAuthType('social')
                  FB.login(handleFacebookLogin)
                }}
              />

              {authType === 'social' && error ? <p>{error}</p> : null}
            </div>
          </>
        ),
      },
      {
        item: 'input',
        label: 'E-post',
        type: 'email',
        value: email,
        onChange: setEmail,
        id: 'email',
      },
      {
        item: 'input',
        label: 'Lag passord',
        type: 'password',
        value: password,
        onChange: setPassword,
        name: 'new-password',
        id: 'new-password',
        autoComplete: 'new-password',
      },
      {
        id: 'login-error',
        item: 'warning',
        value: (authType === 'credentials' && error) || null,
      },
    ],
    onSubmit: signup,
    submitProps: { disabled: !email.length || !password.length },
    submit: 'Lag bruker',
  }

  const hasGivenMarketingConsent = some(get(me, 'node.consents'), {
    type: 'marketing',
  })

  const CONFIRM_STEP = {
    title: eligible ? 'Bekreftelse' : 'Beklager',
    items: [
      ...(eligible
        ? [
            {
              item: 'text',
              value: `Ved å løse inn dette gavekortet får du tilgang til alt innhold på Foodsteps i ${
                get(voucher, 'months') === 1
                  ? 'én måned'
                  : `${get(voucher, 'months')} måneder`
              }.`,
            },
            ...(!hasGivenMarketingConsent
              ? [
                  {
                    item: 'custom',
                    id: 'marketing',
                    children: (
                      <div>
                        <Checkbox
                          label="Jeg ønsker å motta nyheter og tilbud fra Foodsteps via epost"
                          onChange={getConsentHandler('marketing')}
                        />

                        {/*
                <Article.Text>Ønsker du å motta informasjon om nyheter og tilbud i Foodsteps via epost?</Article.Text>
                <Button primary onClick={getConsentHandler('marketing', true)}>Ja takk!</Button>{' '}
                <Button primary onClick={getConsentHandler('marketing', false)}>Nei takk</Button>
                */}
                      </div>
                    ),
                  },
                ]
              : []),
          ]
        : [
            ...issues.reduce((res, key) => {
              if (key === 'active-in-app-subscription') {
                return [
                  ...res,
                  {
                    item: 'text',
                    small: true,
                    left: true,
                    value:
                      'Du kan ikke løse inn gavekort. Din bruker har et aktivt abonnement som betales med betaling gjennom App Store eller Google Play.',
                  },
                  {
                    item: 'text',
                    small: true,
                    left: true,
                    value:
                      'Du må stoppe dette før du kan løse inn gavekortet. Du kan stoppe ditt abonnement på Foodsteps fra min side i appen.',
                  },
                  {
                    item: 'text',
                    small: true,
                    left: true,
                    value:
                      'Når abonnementet betalt gjennom App Store eller Google Play har løpt ut kan du løse inn gavekortet.',
                  },
                ]
              }

              return res
            }, []),
          ]),
      {
        id: 'verify-error',
        item: 'warning',
        value: error || null,
      },
    ],
    ...(eligible
      ? {
          disclaimer: (
            <>
              Ved å fortsette godkjenner du <Link to="/vilkar">vilkårene</Link>{' '}
              for bruk av Foodsteps og bekrefter at du har lest{' '}
              <Link to="/personvern">personvernerklæringen</Link>.
            </>
          ),
          onSubmit: handleRedeemVoucher,
          submit: 'Løs inn gavekort',
        }
      : {}),
  }

  const SUCCESS_STEP = {
    title: 'Alt i boks!',
    close: true,
    items: [
      {
        item: 'text',
        value:
          'Du har nå løst inn gavekortet og er klart til å sette igang. Last ned appen på mobilen din, logg deg på og kos deg på kjøkkenet! ',
      },
      {
        item: 'button',
        label: 'Last ned fra AppStore',
        to: DOWNLOAD_FROM_APPLE,
        id: 'appstore',
        primary: true,
        icon: 'download',
        full: true,
      },
      {
        item: 'button',
        label: 'Last ned fra Google Play',
        to: DOWNLOAD_FROM_GOOGLE,
        id: 'googleplay',
        primary: true,
        icon: 'download',
        full: true,
      },
    ],
  }

  return (
    <>
      <SEO title="Løs inn gavekort" />

      {loading ? <Loader delay={0} type="filled" /> : null}

      <Form
        step={step}
        setStep={moveToStep}
        handleBack={() => navigate('/gavekort')}
        navigation
        steps={{
          'login-or-register': LOGIN_OR_REGISTER_STEP,
          login: LOGIN,
          register: REGISTER_STEP,
          redeem: REDEEM_STEP,
          confirm: CONFIRM_STEP,
          success: SUCCESS_STEP,
        }}
      />
    </>
  )
}
