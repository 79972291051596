import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'

import SOCIAL_SIGNUP_MUTATION from '../queries/social-signup'
import { trackGAEvent } from '../lib/ga'

export default function useSocialSignup(errorState, setAuthType) {
  const [, setError] = errorState
  const [signupSocial] = useMutation(SOCIAL_SIGNUP_MUTATION)

  const [token, setToken] = useState(
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('token')
      : null,
  )

  async function doSocialSignup(accessToken, type) {
    setAuthType('social')
    setError(null)

    if (!accessToken || !type) {
      console.warn(
        'Tried to perform social login with either accessToken or type missing.',
      )
      return
    }

    const {
      data: {
        signupSocial: {
          user,
          token = null,
          error: socialSignupError = null,
        } = {},
      } = {},
    } = await signupSocial({ variables: { type, accessToken } })

    if (socialSignupError) {
      if (socialSignupError.code === 5) {
        setError('En feil skjedde da vi skulle lage kontoen')
      } else {
        setError(socialSignupError.message)
      }
      return
    }

    if (socialSignupError) {
      setError(socialSignupError.message)
      return
    }

    if (user?.id) {
      trackGAEvent({
        event: 'userIdset',
        userId: user?.id,
      })
    }

    setToken(token)
  }

  return { token, signup: doSocialSignup }
}
