import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { validate as validateEmail } from 'email-validator'

import SIGNUP_MUTATION from '../queries/signup'
import { trackGAEvent } from '../lib/ga'

export default function useSignup(errorState, setAuthType, email, password) {
  const [, setError] = errorState
  const [signup] = useMutation(SIGNUP_MUTATION)

  const [token, setToken] = useState(
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('token')
      : null,
  )

  async function doSignup() {
    setAuthType('credentials')
    setError(null)

    if (!email || !password) {
      setError('Fyll inn e-post og passord')
      return
    }

    if (!validateEmail(email)) {
      setError('Skriv inn en gyldig e-postadresse')
      return
    }

    const {
      data: {
        signup: { user, token = null, error: signupError = null } = {},
      } = {},
    } = await signup({ variables: { email, password } })

    if (signupError) {
      if (signupError.code === 2) {
        setError('Det finnes alt en bruker med samme e-postadresse')
      } else if (signupError.code === 5) {
        setError('En feil skjedde da vi skulle lage kontoen')
      } else if (signupError.code === 10) {
        setError('E-postadressen er ugyldig')
      } else {
        setError(signupError.message)
      }
    }

    if (user?.id) {
      trackGAEvent({
        event: 'userIdset',
        userId: user?.id,
      })
    }

    setToken(token)
  }

  return { token, signup: doSignup }
}
